<template>
<div class="tgju-widget-content full-padding w100-w">
    <slot v-if="widgetLoad">
        <div class="col-12 col-lg-12 col-xl-12 col-xxl-12">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
    </slot>
    <slot v-else>
        <div class="row tgju-widgets-row" v-if="technicals.data.length">
            <div class="col-12 col-lg-6 col-xl-6 col-xxl-4" v-for="technical in technicals.data" :key="technical.id">
                <div class="tgju-widget light post mb-3">
                    <div class="post-row">
                        <div class="post-row-title-xl">
                            <div class="post-row-title-content">
                                <router-link class="h2" :to="{ name: 'ViewTechnical' , params: {id: technical.id}}">
                                    {{ technical.title}}
                                </router-link>
                                <div class="post-row-title-tag">
                                    <a :href="technical.symbol_url" target="_blank" v-tooltip="'بازار تحلیل شده'">
                                        {{ technical.market_title }}
                                    </a>
                                    <div class="tag-flex-grow-1"></div>
                                    <!-- <div class="timeframe"><span v-tooltip="'تایم‌فریم تحلیل'" class="display-inline-block">| 1ماهه</span></div> -->
                                    <!-- <div v-tooltip="'بازه تحلیل'" class="l-tag green-tag">لانگ <i class="uil uil-arrow-up-left"></i></div> -->
                                    <div v-if="technical.time_duration == 'logterm'" v-tooltip="'بازه تحلیل بلند مدت'" class="l-tag green-tag">بلند‌مدت <i class="uil uil-arrow-up-left"></i></div>
                                    <div v-else-if="technical.time_duration == 'midterm'" v-tooltip="'بازه تحلیل میان مدت'" class="l-tag yellow-tag">میان‌مدت <i class="uil uil-minus"></i></div>
                                    <div v-else-if="technical.time_duration == 'shortterm'" v-tooltip="'بازه تحلیل کوتاه مدت'" class="l-tag red-tag">کوتاه‌مدت <i class="uil uil-arrow-down-left"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="tgju-widget-content profile-post-content pb-0">
                            <router-link class="technical-image-post hover-popper" :to="{ name: 'ViewTechnical' , params: {id: technical.id}}" data-popper-type="user" data-popper-id="1">
                                <slot v-if="$helpers.isJson(technical.image) && technical.image">
                                    <img :src="$helpers.api_url() + $helpers.json_pars(technical.image)[0].name">
                                </slot>
                                <slot v-else>
                                    <img src="/chart.png">
                                </slot>
                            </router-link>
                            <div class="post-row-author">
                                <img v-if="technical.user.avatar" :src="$helpers.api_url() + $helpers.json_pars(technical.user.avatar).name">
                                <img v-else :src="'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                                <div class="user">
                                    <router-link :to="{ name: 'ProfileOthers' , params : { username: technical.user.username } }">
                                        {{ technical.user.firstname }} {{ technical.user.lastname }}
                                    </router-link>
                                    <div class="tag user-tag-primary" v-tooltip="'سطح کاربری'">
                                        تحلیل‌گر
                                    </div>
                                </div>
                                <div class="date">
                                    <div class="display-inline-block" v-tooltip="'تاریخ تحلیل'">
                                        {{ toRelative(technical.created_at) }}
                                    </div>
                                </div>
                            </div>
                            <div class="post-row-body">
                                {{ technical.content }}
                            </div>
                        </div>
                        <div class="post-btn-row comment-btn-row">
                            <div v-if="checkMyTechnicals && hiddenTimer(technical.created_at, deleteTimer)" class="post-btn-item  post-btn-item-right" v-tooltip="'حذف تحلیل'">
                                <i aria-hidden="true" class="uil uil-trash post-btn-icon pointer" style="color: #e63946;" @click="removeTechnical(technical.id)"></i>
                            </div>
                            <div v-if="checkMyTechnicals && hiddenTimer(technical.created_at, editTimer)" class="post-btn-item  post-btn-item-right" v-tooltip="'ویرایش تحلیل'">
                                <i aria-hidden="true" class="uil uil-edit-alt post-btn-icon pointer" @click="editTechnical(technical.id)"></i>
                            </div>
                            <div class="post-btn-item post-btn-item-right" v-tooltip="'لایک تحلیل'">
                                <i aria-hidden="true" class="uil uil-thumbs-up post-btn-icon pointer" @click="likeTechnical(technical.id)" :style="technical.is_like > 0 ? 'color: #e63946;' : ''"></i>
                                <span class="post-btn-count">
                                    {{ technical.likes }}
                                </span>
                            </div>
                            <div class="post-btn-grow">
                            </div>
                            <router-link :to="{ name: 'ViewTechnical' , params: {id: technical.id}}" class="post-btn-item" v-tooltip="'دیدگاه‌های تحلیل'">
                                <i aria-hidden="true" class="uil uil-comment-alt-lines post-btn-icon pointer"></i>
                                <span class="post-btn-count">
                                    {{ technical.comments }}
                                </span>
                            </router-link>
                            <dropdown-menu class="dropdown-top" v-model="technical.showShare">
                                <a href="#" class="post-btn-item dropdown-toggle" @click.prevent="" v-tooltip="'بازنشر تحلیل'">
                                    <i aria-hidden="true" class="uil uil-share-alt post-btn-icon pointer"></i>
                                </a>
                                <div slot="dropdown">
                                    <a class="dropdown-item" @click="shareTechnical('facebook',technical.title,technical.id,technical.market_symbol)"><i class="uil uil-facebook-f" aria-hidden="true"></i>اشتراک در فیسبوک</a>
                                    <a class="dropdown-item" @click="shareTechnical('twitter',technical.title,technical.id,technical.market_symbol)"><i class="uil uil-twitter-alt" aria-hidden="true"></i>اشتراک در تویتر</a>
                                    <a class="dropdown-item" @click="shareTechnical('telegram',technical.title,technical.id,technical.market_symbol)"><i class="uil uil-telegram-alt" aria-hidden="true"></i>اشتراک در تلگرام</a>
                                </div>
                            </dropdown-menu>
                            <div class="post-btn-item" v-tooltip="'افزودن به علاقه‌مندی‌ها'">
                                <i aria-hidden="true" class="uil uil-bookmark-full post-btn-icon pointer" @click="bookmarkTechnical(technical.id)" :style="technical.is_bookmark > 0 ? 'color: #e63946;' : ''"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row tgju-widgets-row">
            <div class="col-12 col-lg-12 col-xl-12 col-xxl-12">
                <div class="tgju-widget light post mb-3">
                    <div class="no-content d-flex justify-content-center">
                        <div class="text-center p-2">
                            <div class="not-found-items">
                                <i class="uil uil-chart"></i>
                                <br>
                                تحلیلی برای نمایش موجود نمی باشد.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="technicals.last_page > 1" class="p-5 d-flex justify-content-center">
            <Paginate :page-count="technicals.last_page" :click-handler="my_technicals ? getMyTechnical : getTechnical" :prev-text="'قبل'" :next-text="'بعد'" :container-class="'pagination'"></Paginate>
        </div>
    </slot>
</div>
</template>

<style lang="scss" scoped>
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
</style>

<script>
// این کامپوننت دارای تمام اجزای نمایش لیست عمومی تحلیل می باشد
import moment from "moment-jalaali";
import Paginate from "vuejs-paginate";
import DropdownMenu from '@innologica/vue-dropdown-menu';

export default {
    name: 'ProfileTechnicalMobile',
    props: ['filter', 'hot', 'my_technicals','time', 'market', 'bookmark'],
    components: {
        'dropdown-menu': DropdownMenu,
        Paginate
    }, 
    data: function () {
        return {
            technicals: [],
            widgetLoad: true,
            like_process: false,
            bookmark_process: false,
            editTimer: 10,
            deleteTimer: 10,
        }
    },
    mounted() {
        
        // در صورتی که منوی داشبورد مخفی بود نمایش داده شود
        // var item = document.getElementsByClassName('bar-menu-dashboard-box');
        // item[0].style.display = "none";

        // دریافت اطلاعات تحلیل های تایید شده ی عمومی
        if (this.my_technicals) {
            this.getMyTechnical()
        } else {
            this.getTechnical();
        }
    },
    computed: {
        checkMyTechnicals: {
            get() {
                return this.$parent.selectedMyTechnicals;
            }
        },
    },
    methods: {
        hiddenTimer(created_at, timer) {
            let currentDate = moment().format();
            let minutesDiff = moment(currentDate).diff(created_at, 'minutes');
            return (minutesDiff > timer) ? false : true;
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
                // این متد جهت ارسال درخواست به وب سرویس جهت دریافت لیست تحلیل های کاربر می باشد
        getMyTechnical(page) {
            let api_data = {
                page: page ? page : 1,
                filter: this.hot ? this.hot : (this.filter ? this.filter : 'newest'),
                time: this.time ? this.time : 'all',
                market: this.market ? this.market : 'all',
                bookmark: this.bookmark,
            };
            // ارسال در خواست به وب سرویس
            this.$helpers.makeRequest('GET', '/technical/my-list', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                var list = [];
                var newArray = api_response.data.response.technicals;
                newArray.data.forEach(e => {
                    e["showShare"] = false;
                    list.push(e);
                });
                newArray.data = list;
                this.technicals = newArray;
                this.widgetLoad = false;
                this.$parent.$refs.top.scrollIntoView({block: "end"});
            });
        },
        // این متد جهت ارسال درخواست به وب سرویس جهت دریافت لیست تحلیل های کاربران می باشد
        getTechnical(page) {
            let api_data = {
                page: page ? page : 1,
                filter: this.hot ? this.hot : (this.filter ? this.filter : 'newest'),
                time: this.time ? this.time : 'all',
                market: this.market ? this.market : 'all',
                bookmark: this.bookmark,
            };

            // ارسال در خواست به وب سرویس
            this.$helpers.makeRequest('GET', '/technical/all-list', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                var list = [];
                var newArray = api_response.data.response.technicals;
                newArray.data.forEach(e => {
                    e["showShare"] = false;
                    list.push(e);
                });
                newArray.data = list;
                this.technicals = newArray;
                this.widgetLoad = false;
                this.$parent.$refs.top.scrollIntoView({block: "end"});
            });
        },
        // این متد برای هندل کردن درخواست لایک کردن و آنلایک کردن یک تحلیل مورد استفاده قرار میگیرد 
        likeTechnical(id) {
            if (this.like_process) {
                return false;
            }

            this.like_process = true;
            let index = this.$helpers.getIndex(this.technicals.data, id);

            var action = this.technicals.data[index].is_like > 0 ? 'unlike' : 'like';
            let api_data = {
                action: action
            };
            // ارسال درخواست به وب سرویس
            this.$helpers.makeRequest('GET', '/technical/like/' + id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.technicals.data[index].is_like = action == 'like' ? 1 : 0;
                    action == 'like' ?
                        this.technicals.data[index].likes++
                        :
                        this.technicals.data[index].likes--;

                    this.like_process = false;
                }
            });
        },
        // این متد جهت هندل کردن درخواست بوک مارک کردن یک تحلیل به کار میرود
        bookmarkTechnical(id) {
            if (this.bookmark_process) {
                return false;
            }

            this.bookmark_process = true;
            let index = this.$helpers.getIndex(this.technicals.data, id);

            var action = this.technicals.data[index].is_bookmark > 0 ? 'remove' : 'add';
            let api_data = {
                action: action
            };

            this.$helpers.makeRequest('GET', '/technical/bookmark/' + id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.technicals.data[index].is_bookmark = action == 'add' ? 1 : 0;
                    this.bookmark_process = false;
                }
            });
        },
        // این متد جهت به اشتراک گذاری یک تحلیل استفاده میشود
        shareTechnical(type, title, id, market_symbol) {
            this.$helpers.socialShare(type, title, process.env.VUE_APP_URL + '/technical/view/' + id)
        },
        // این متد جهت ویرایش یک تحلیل می باشد
        editTechnical(id) {
            let index = this.$helpers.getIndex(this.technicals.data, id);
            let callbackEvent = this.$router.currentRoute.name + '_EditTechnical_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'TechnicalEdit.vue',
                config: {
                    title: 'ویرایش تحلیل',
                    icon: 'uil uil-edit-alt with-icon',
                    smallModal: true,
                    data: {
                        technical: this.$helpers.unbindObject(this.technicals.data[index]),
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);
            // هندل کال بک
            this.$root.$on(callbackEvent, (response) => {
                let api_data = {
                    id: id,
                    title: response.data.technical.title,
                    body: response.data.technical.content,
                    type: response.data.technical.private,
                    time_duration: response.data.technical.time_duration,
                    technical_type: response.data.technical.technical_type,
                    attachment: response.data.technical.attachment,
                };

                this.$helpers.makeRequest('POST', '/technical/edit-chart', api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);
                    this.widgetLoad = true;
                    this.$swal.fire({
                        icon: api_response.data.status == true ? "success" : 'error',
                        title: api_response.data.status == true ? "ویرایش با موفقیت انجام شد" : api_response.data.message,
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.getMyTechnical();
                });
            });
        },
        // این متد جهت حذف یک تحلیل از سیستم می باشد
        removeTechnical(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این مورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    let index = this.$helpers.getIndex(this.technicals.data, id);
                    this.technicals.data.splice(index, 1);
                    this.$helpers.makeRequest('DELETE', '/technical/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            this.$swal.fire({
                                icon: "success",
                                title: "تحلیل مورد نظر با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف تحلیل رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
    },
}
</script>
